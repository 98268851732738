import React, { ReactNode } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import BlogItem, { options } from "../components/blogItem"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NotFoundPage from "./404"
import CrButton from "../components/arrowButton"
import "../styles/career.scss"

const Job = props => {
  if (!props.data.allContentfulJobs.edges) {
    return <NotFoundPage />
  }

  const slug = props.pageContext.slug
  const otherJobProps = props.pageContext.otherJobs
    ? Object.values(props.pageContext.otherJobs).filter(
        obj => obj.node.slug != slug
      )
    : []
  let jobProps = props.data.allContentfulJobs?.edges[0]?.node

  const richTextFn = richData => {
    let jdDescription = richData
    jdDescription = renderRichText(jdDescription, options)
    return <div className="blog-body__description">{jdDescription}</div>
  }

  return (
    <Layout data={{ headerType: "dark" }}>
      <SEO
        title="Coderapper | eCommerce Digital Agency | Shopify Experts"
        description="Scale your brand with leading Shopify experts and Magento consultants. Build eCommerce stores and products that wow customers, drive revenue and deliver results."
      />
      <section className="jd-head">
        <div className="container-lg">
          <div className="row extra-gutter left right">
            <div className="col-md-10 mx-auto">
              <div className="jd-head__title">
                <div className="d-md-flex g-0 justify-content-between align-items-end">
                  <div className="jd-head__meta">
                    <div className="section-tag white">
                      <span>Careers</span>
                    </div>
                    <h1 className="text text-h2 text-title-font text-white text-fw-regular gap-0">
                      {jobProps.jobTitle}
                    </h1>
                    <ul className="jd-head__meta-list list-unstyled d-flex px-0">
                      {jobProps.remoteJob && (
                        <li className="jd-head__meta-item">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                          >
                            <path
                              d="M1 5.13828L1.29816 5.48415C5.15924 2.19105 10.8408 2.19105 14.7018 5.48415L15 5.13828C10.9671 1.6987 5.03288 1.6987 1 5.13828Z"
                              fill="white"
                            />
                            <path
                              d="M2.67236 7.9445L2.99518 8.26732C5.76077 5.50784 10.2382 5.50784 13.0038 8.26732L13.3266 7.9445C10.3827 5.00678 5.61628 5.00678 2.67236 7.9445Z"
                              fill="white"
                            />
                            <path
                              d="M4.93262 10.2044L5.25543 10.5272C6.77214 9.01404 9.2275 9.01404 10.7442 10.5272L11.067 10.2044C9.3719 8.51324 6.62774 8.51324 4.93262 10.2044Z"
                              fill="white"
                            />
                            <circle cx="8" cy="13" r="1" fill="white" />
                          </svg>
                          Remote
                        </li>
                      )}

                      {jobProps.experience && (
                        <li className="jd-head__meta-item">
                          {jobProps.experience}
                        </li>
                      )}

                      <li className="jd-head__meta-item">
                        {jobProps.jobType.map(item => item)}
                      </li>
                    </ul>
                  </div>
                  <div className="jd-head__action">
                    <CrButton
                      path={
                        "mailto:careers@coderapper.com?subject=Applying for" +
                        jobProps.jobTitle
                      }
                      target="_blank"
                      btnType="link"
                      type="dark"
                      label="Apply Now"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-lg">
        <div className="row extra-gutter left right">
          <div className="col-md-10 mx-auto">
            <section className="jd__role-description">
              <h2 className="text text-h3 text-title-font text-black text-fw-regular gap-1x">
                General role description
              </h2>
              {richTextFn(jobProps.roleDescription)}
            </section>

            <section className="jd__role-responsibility">
              <h2 className="text text-h3 text-title-font text-black text-fw-regular gap-1x">
                Analytics Practice
              </h2>
              {richTextFn(jobProps.jobResponsibilities)}
            </section>

            <section className="jd__role-experience mb-0">
              <h2 className="text text-h3 text-title-font text-black text-fw-regular gap-1x">
                Credentials & experience
              </h2>
              {richTextFn(jobProps.credentialsExperience)}
            </section>

            <div className="jd__action">
              <CrButton
                path="mailto:careers@coderapper.com"
                target="_blank"
                btnType="link"
                type="light"
                label="Apply Now"
              />
            </div>
          </div>
        </div>
      </div>

      <section className="other-job">
        <div className="container-lg">
          <h2 className="text text-h5 text-title-font text-black text-fw-regular gap-1x">
            Other roles
          </h2>
          <div className="row">
            {otherJobProps.map((otherJob, key) => {
              let jobItem = otherJob.node
              return (
                <div className="col-md-4">
                  <div className="job-item job-item--grid">
                    <div className="job-item__designation">
                      <h5>{jobItem.jobTitle}</h5>
                      {jobItem.remoteJob ? (
                        <h6>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                          >
                            <path
                              d="M1 5.13828L1.29816 5.48415C5.15924 2.19105 10.8408 2.19105 14.7018 5.48415L15 5.13828C10.9671 1.6987 5.03288 1.6987 1 5.13828Z"
                              fill="black"
                            />
                            <path
                              d="M2.67236 7.9445L2.99518 8.26732C5.76077 5.50784 10.2382 5.50784 13.0038 8.26732L13.3266 7.9445C10.3827 5.00678 5.61628 5.00678 2.67236 7.9445Z"
                              fill="black"
                            />
                            <path
                              d="M4.93262 10.2044L5.25543 10.5272C6.77214 9.01404 9.2275 9.01404 10.7442 10.5272L11.067 10.2044C9.3719 8.51324 6.62774 8.51324 4.93262 10.2044Z"
                              fill="black"
                            />
                            <circle cx="8" cy="13" r="1" fill="black" />
                          </svg>
                          Remote
                        </h6>
                      ) : (
                        <h6>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.00251 4.22078C7.4525 4.22078 6.91484 4.38386 6.45751 4.68939C6.00018 4.99493 5.64371 5.4292 5.43316 5.93731C5.22262 6.44542 5.16745 7.00455 5.27465 7.54401C5.38184 8.08346 5.64657 8.57903 6.03538 8.96804C6.42419 9.35706 6.91961 9.62206 7.45901 9.72954C7.99841 9.83702 8.55757 9.78216 9.06579 9.57188C9.57401 9.36161 10.0085 9.00537 10.3143 8.5482C10.62 8.09103 10.7834 7.55346 10.7837 7.00346C10.7829 6.26594 10.4897 5.55884 9.96833 5.0372C9.44696 4.51556 8.74002 4.22196 8.00251 4.22078V4.22078ZM8.00251 9.48289C7.51105 9.48289 7.03063 9.3371 6.62206 9.06397C6.21349 8.79084 5.89513 8.40264 5.70726 7.94851C5.51939 7.49438 5.47046 6.99472 5.56667 6.51277C5.66287 6.03082 5.89988 5.58824 6.24771 5.24104C6.59553 4.89384 7.03854 4.65762 7.52066 4.56229C8.00278 4.46695 8.50235 4.51677 8.95615 4.70546C9.40994 4.89414 9.79756 5.2132 10.07 5.62227C10.3424 6.03133 10.4873 6.512 10.4864 7.00346C10.4844 7.66121 10.222 8.2914 9.75645 8.75609C9.29092 9.22077 8.66026 9.48211 8.00251 9.48289Z"
                              fill="black"
                            />
                            <path
                              d="M8.00248 2C7.13431 1.99992 6.28106 2.2258 5.52665 2.65543C4.77223 3.08506 4.14263 3.70366 3.69976 4.45037C3.25689 5.19709 3.01599 6.04622 3.00077 6.91426C2.98554 7.7823 3.19651 8.63936 3.61293 9.40115C4.82291 11.8613 7.74978 14.4135 7.87316 14.5221L8.00843 14.5875L8.12883 14.5384L8.15113 14.5221C8.28491 14.4016 11.5343 11.564 12.5882 8.99683L12.6105 8.93291L12.6596 8.80508C12.9545 8.04798 13.0601 7.23036 12.9674 6.42318C12.8746 5.616 12.5863 4.84365 12.1275 4.17314C11.6686 3.50264 11.053 2.95424 10.3342 2.57554C9.61536 2.19684 8.81497 1.99928 8.00248 2V2ZM12.3861 8.69805L12.34 8.81994L12.3147 8.88089C11.3619 11.1968 8.45734 13.8457 8.00991 14.2396C7.58627 13.868 4.97008 11.4837 3.87603 9.26291C3.49375 8.56928 3.29264 7.79041 3.29133 6.99841C3.29002 6.2064 3.48854 5.42687 3.86853 4.73198C4.24851 4.03708 4.79768 3.44929 5.46518 3.02301C6.13267 2.59673 6.89693 2.34574 7.68719 2.29329C8.47746 2.24083 9.26818 2.3886 9.98617 2.72292C10.7041 3.05724 11.3262 3.5673 11.7947 4.20587C12.2632 4.84445 12.563 5.5909 12.6664 6.37613C12.7697 7.16136 12.6733 7.95998 12.3861 8.69805V8.69805Z"
                              fill="black"
                            />
                          </svg>
                          {jobItem.location}
                        </h6>
                      )}
                    </div>
                    <div className="job-item__hour">{jobItem.jobType}</div>
                    <div className="job-item__exp">
                      Exp {jobItem.experience}
                    </div>
                    <a href={`/${jobItem.slug}`}></a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Job

export const pageQuery = graphql`
  query($slug: String) {
    allContentfulJobs(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          jobTitle
          jobType
          roleDescription {
            raw
          }
          jobResponsibilities {
            raw
          }
          credentialsExperience {
            raw
          }
          createdAt(formatString: "YYYY MMMM DD")
          experience
          remoteJob
          location
          slug
        }
      }
    }
  }
`
